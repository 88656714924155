import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public activeLang = 'es'; 
  constructor(private translate: TranslateService, private _data: DataService) { }

  /**
	 * guarda informacion en el localStorage
	 * @param nombre
   * @param valor
	 */
  addStore(nombre: string, valor:any){
    let valorString = JSON.stringify(valor);
    localStorage.setItem(nombre, valorString);
  }

  /**
	 * recuperar variable en localStorage
	 * @param nombre
	 */
  retriveStorage(nombre: string) : any{
    let getData = localStorage.getItem(nombre);
    return JSON.parse(getData);
  }

  cambiarLeng(lang) {
    console.log("Cambiar lang");
    this.activeLang = lang;
    this.translate.use(lang);

    this._data.addStore('lang',lang);
  }

  /**
   * captura los errores de las peticiones rest.
   * 
   * nota: handleError no detecta llamadas a otros metodos
   */
  handleError(errorResponse: HttpErrorResponse):Observable<never> {
    console.log(errorResponse);
    
    let errorList: ResponseVO<null> = errorResponse.error;
    if(errorList.errors){
      return throwError(errorList.errors);
    }
    let error: ErrorVO[] = [];
    let errorVO : ErrorVO;
    errorVO = new ErrorVO();
    errorVO.message = notificationMessage.CONECTION_FAILED;
    error.push(errorVO);
    return throwError(error);
  }
}
export const notificationMessage = {
  CONECTION_FAILED: 'Error inesperado, intenta mas tarde.',
  SESSION_INVALID: 'Sesión no valida.',
  SAVE: 'Guardado.',
  CREATE: 'creado exitosamente.',
  UPDATE: 'modificado exitosamente.',
  DELETE: 'borrado exitosamente.'
};

export class ResponseVO<T> {
  success: boolean;
  errors: ErrorVO[];
  data: T;
  totalRows?: number;
}

export class ErrorVO {
  code: string;
  message: string;
}
export class RequestVO<T> {
  page: number;
  size: number;
  orderBy: string;
  orderType: string;
  token: string;
  parameters: T;
}
