import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss']
})
export class TranslateComponent implements OnInit {

  public activeLang = 'es'; 
  
  constructor(
    private translate: TranslateService,
    private _data: DataService
  ) {
    this.translate.setDefaultLang(this.activeLang);
  }

  ngOnInit() {
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    this.translate.use(lang);

    this._data.addStore('lang',lang);
  }

  /** Se pueden ir a servicios comunes ejemplo a un data.service.ts */
  /**
	 * guarda informacion en el localStorage
	 * @param nombre
   * @param valor
	 */

  /*
  addStore(nombre: string, valor:any){
    let valorString = JSON.stringify(valor);
    localStorage.setItem(nombre, valorString);
  }
  */

  /**
	 * recuperar variable en localStorage
	 * @param nombre
	 */

  /*
  retriveStorage(nombre: string) : any{
    let getData = localStorage.getItem(nombre);
    return JSON.parse(getData);
  }
  */

}
