import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdministradoresComponent } from './administradores/administradores.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdministradorDetalleComponent } from './administrador-detalle/administrador-detalle.component';
import { AdministradorVisualizacionComponent } from './administrador-visualizacion/administrador-visualizacion.component';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@NgModule({
  declarations: [AdministradoresComponent, AdministradorDetalleComponent, AdministradorVisualizacionComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [ HttpClient ]
      }
    })
  ],
  exports: [
    AdministradoresComponent
  ]
})
export class AdminModule { }
