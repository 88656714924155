import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  animations: [
    trigger('myAnimationTrigger', [
      transition('* => someState', [
        // animations go here
      ]),
      transition('* => visible', [
        // fade out the element immediately
        style({ opacity: 0 }),
    
        //...
      ]),
    
      transition('* => hidden', [
        // use the existing opacity on the element
        style({ opacity: '*' }),
    
        //...
        animate('3000ms', style({ opacity: 0 }))
      ])
    ]),
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('3s', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('1s', style({ opacity: 0 }))
      ])
    ]),
    trigger('routeAnimations', [
      /*
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('1s', style({ opacity: 0 }))
      ])
      */
    ]),
    trigger('changeDivSize', [
      state('initial', style({
        backgroundColor: 'green',
        width: '100px',
        height: '100px'
      })),
      state('final', style({
        backgroundColor: 'red',
        width: '200px',
        height: '200px'
      })),
      transition('initial=>final', animate('1500ms')),
      transition('final=>initial', animate('1000ms'))
    ]),
    trigger('changeMenu', [
      state('initial', style({
        backgroundColor: 'green',
        width: '100px',
        height: '100px',
        opacity: 0,
        display: 'none'
      })),
      state('final', style({
        backgroundColor: 'red',
        width: '200px',
        height: '200px',
        opacity: 1,
        display: 'inline'
      })),
      transition('initial=>final', animate('150ms')),
      transition('final=>initial', animate('100ms'))
    ])
    
  ]
})
export class BlogComponent implements OnInit {

  exp = "hola";
  title = 'angular-web';
  myValue = 'someState';
  currentState = 'initial';
  currentMenuState = 'initial';
  someStateValue = 'initial';
  
  constructor() { }

  ngOnInit() {
  }

  changeState() {
    this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
  }
  changeMenuState(){
    this.currentMenuState = this.currentMenuState === 'initial' ? 'final' : 'initial';
  }

}
